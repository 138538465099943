import { login } from '@marmalade/account/lib/actions';
import { isLoggingIn, loginError } from '@marmalade/account/lib/selectors';
import Button, { LinkButton } from '@marmalade/button';
import Form from '@marmalade/form/lib/components/form';
import Input from '@marmalade/form/lib/components/input';
import Password from '@marmalade/form/lib/questions/password';
import Message from '@marmalade/message';
import required from '@marmalade/validation/lib/required';
import { Div, H2, withTheme } from 'glamorous';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ButtonGrid from '../../../../marketing/components/button-grid';
import PanelWhite from '../../../../marketing/components/panel-white';
import { isMobile, isTablet } from '../../../../selectors';

LoginPanel.propTypes = {
    loginError: string.isRequired,
    posting: bool.isRequired,
    mobile: bool.isRequired,
    tablet: bool.isRequired,
    login: func.isRequired,
    theme: object.isRequired,
};

const reverseIf = condition => array => (condition ? array.reverse() : array);

const handleLogin = action => ({ values }) => {
    action('broker', values.username, values.password);
};

function LoginPanel({ mobile, tablet, login, theme, posting, loginError }) {
    return (
        <Div
            marginTop={tablet ? undefined : '4rem'}
            alignSelf={mobile ? 'center' : undefined}
            display="flex"
            justifyContent="center"
            width="100%"
            maxWidth="37.5rem"
        >
            <PanelWhite flexGrow={1} maxWidth="37.5rem" textAlign="left">
                <H2 fontSize="2rem">Login to Broker Portal</H2>
                <Form
                    id="login"
                    handleSubmit={handleLogin(login)}
                    validator={{
                        username: required('Please enter your username'),
                        password: required('Please enter your password'),
                    }}
                >
                    {loginError ? <Message type="warning">{loginError}</Message> : null}
                    <Input label="Username" name="username" />
                    <Password
                        extra={
                            <Div fontSize="1.4rem">
                                <Link to="/reset-password">Forgotten password?</Link>
                            </Div>
                        }
                    />

                    <ButtonGrid vertical={tablet}>
                        {reverseIf(tablet)([
                            <Div
                                key="register"
                                margin={0}
                                marginTop={tablet ? 5 : 0}
                                marginRight={tablet ? undefined : 5}
                            >
                                <LinkButton to="/register" block>
                                    Register
                                </LinkButton>
                            </Div>,
                            <Div key="login" margin={0} marginLeft={tablet ? undefined : 5}>
                                <Button block posting={posting} flexGrow={1} prominence="primary" type="submit">
                                    Login
                                </Button>
                            </Div>,
                        ])}
                    </ButtonGrid>
                </Form>
            </PanelWhite>
        </Div>
    );
}

export default withTheme(
    connect(
        (state, { theme }) => ({
            loginError: loginError(state),
            mobile: isMobile(theme)(state),
            tablet: isTablet(theme)(state),
            posting: isLoggingIn(state, 'login'),
        }),
        { login }
    )(LoginPanel)
);
