import { Img } from 'glamorous';
import { bool } from 'prop-types';
import React from 'react';
import bibaWatermark from '../../marketing/media/biba.png';

BulletList.propTypes = {
    opaque: bool,
};

function BulletList({ opaque = false, ...rest }) {
    return (
        <Img
            {...rest}
            src={bibaWatermark}
            maxWidth={opaque ? 110 : 214}
            maxHeight={opaque ? 110 : 214}
            opacity={opaque ? 1 : 0.3}
        />
    );
}

export default BulletList;
