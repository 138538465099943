import { Div, withTheme } from 'glamorous';
import { number, object, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getWidth } from 'redux-window';
import { H1 } from '../../../components/typography';
import Watermark from '../../../marketing/components/watermark';
import { getBreakpoint } from '../../../modules/get-breakpoint';
import BulletList from './components/bullet-list';
import LoginPanel from './components/login-panel';
import Subtitle from './components/subtitle';

HeroContent.propTypes = {
    width: number.isRequired,
    theme: object.isRequired,
    heading: string,
};

function HeroContent({ width, theme, heading = 'Insurance solutions for parents of young drivers' }) {
    switch (getBreakpoint(width, theme)) {
        case 'xs':
        case 'sm':
            return (
                <Div display="flex" flexDirection="column" alignItems="center" textAlign="center" marginTop="4rem">
                    <H1>{heading}</H1>
                    <LoginPanel />
                    <Subtitle />
                    <Watermark opaque />
                    <BulletList />
                </Div>
            );
        case 'md':
        case 'lg':
            return (
                <Div display="flex" paddingTop="5rem" justifyContent="center" textAlign="center" flexDirection="column">
                    <H1>{heading}</H1>
                    <Div display="flex" justifyContent="space-around">
                        <Div display="flex" flexDirection="column" maxWidth="33rem">
                            <Subtitle />
                            <Watermark opaque flexShrink={1} alignSelf="center" />
                            <BulletList />
                        </Div>
                        <LoginPanel />
                    </Div>
                </Div>
            );
        default:
            return (
                <Div display="flex" paddingTop="5rem" justifyContent="space-between">
                    <Div maxWidth="55rem">
                        <H1 marginTop="3.4rem">{heading}</H1>
                        <Subtitle />
                        <BulletList />
                    </Div>
                    <Watermark flexShrink={1} alignSelf="flex-end" marginBottom="5rem" />
                    <LoginPanel />
                </Div>
            );
    }
}

export default withTheme(
    connect(state => ({
        width: getWidth(state),
    }))(HeroContent)
);
