import React from 'react';
import BulletListComponent from '../../../../marketing/components/bullet-list';

function BulletList() {
    return (
        <BulletListComponent>
            {Li =>
                [
                    'Trusted by BIBA and 1,000’s of Brokers in the UK',
                    'One broker portal for all our young driver products',
                    'Dedicated broker support team',
                    'Give customers a quick quote in seconds',
                ].map((feature, i) => <Li key={`${i}`}>{feature}</Li>)
            }
        </BulletListComponent>
    );
}

export default BulletList;
