import { Container } from '@marmalade/grid';
import glamorous, { Div, P, Section, withTheme } from 'glamorous';
import React from 'react';
import { H1, H2, H3 } from '../../components/typography';
import Hero from '../../marketing/components/hero';
import Layout from '../../marketing/components/layout';

const copyStyles = ({ theme, children, ...rest }) => ({
    color: theme.colorGrey,
    ...rest,
});

const Copy = glamorous.p(copyStyles);

function CookiePolicy() {
    return (
        <Layout noLogin>
            <Hero background="linear-gradient(#F3B263, #f5763e)" backgroundSize="auto 100%" color="#f9f9f9" angle={0}>
                <Container>
                    <Div height="25rem" display="flex" flexDirection="column" justifyContent="center">
                        <H1 marginTop="7rem" marginBottom="2.4rem">
                            Cookie Policy
                        </H1>
                        <P marginBottom="7rem" fontSize="2rem" maxWidth="50rem">
                            Just hoping to bake your day a little better.
                        </P>
                    </Div>
                </Container>
            </Hero>
            <Section backgroundColor="#f9f9f9" paddingBottom="10rem">
                <Container>
                    <H2 marginTop="4.6rem">Our cookie policy</H2>
                    <Copy>
                        This website (www.marmaladebroker.co.uk and all subdomains of www.marmaladebroker.co.uk) is
                        operated by Marmalade. Marmalade websites use cookies to identify users with some of these
                        cookies essential to make our website display information that matches the user’s requirements.
                    </Copy>
                    <H3>What are cookies?</H3>
                    <Copy>
                        Cookies are small, often encrypted text files, which is a feature of web browser software that
                        allows web servers to store information within your browser, which, in turn, allows us to
                        recognise the computer or device used to access our websites. Some cookies are only used to
                        store information about a session with some stored permanently. Most browsers automatically
                        accept cookies but you can delete existing cookies from your browser. You can also edit your
                        browser options to choose not to receive cookies.
                    </Copy>
                    <Copy>We use three types of cookies on our websites</Copy>
                    <ul>
                        <li>Session cookies that are deleted after each visit.</li>
                        <li>Persistent cookies that remain in place across multiple visits to our sites.</li>
                        <li>Third party cookies that are used by our approved business partners.</li>
                    </ul>
                    <H3>Essential cookies</H3>
                    <Copy>
                        Certain cookies are necessary in order for you to use our websites. These are used 'in-session'
                        each time you visit and then expire when you leave the site. They're not stored on your computer
                        and they don't contain any personal data.
                    </Copy>
                    <H3>Personal Data</H3>
                    <Copy>
                        Marmalade will not use cookies to collect personally identifiable information about you. Any
                        personal information that Marmalade does collect through cookies will be at your instigation by
                        applying for or requesting further information about any of the services that we supply through
                        our secure site (identified with an 'https' at the start of the address). For example: applying
                        for an insurance product, finance or simply requesting a call back.
                    </Copy>
                    <H3>Live chat service</H3>
                    <Copy>
                        Our websites may use third party web chat services which use cookies and JavaScript which are
                        files stored on your computer enabling web chat providers to collect data and monitor your
                        browsing activities on our websites. They may disclose information of this nature in aggregate
                        form to other third parties for measurement and analysis. Our operations staff may also be able
                        to monitor your real-time browsing on our websites.
                    </Copy>
                    <H3>Analytics Cookies</H3>
                    <Copy>
                        We may also collect information about your visit to our websites, based on your browsing
                        activities on our websites. This information may include the pages you browse and the products
                        and services viewed or purchased as an example. This helps us to better manage and develop our
                        offering and to provide you with better products and services tailored to your individual
                        interests and needs. We may use this information to measure the entry and exit points of
                        visitors to the site and respective numbers of visitors to various pages and sections of the
                        site and details of click actions performed.
                    </Copy>
                    <Copy>
                        We use third-party application service providers to perform some tracking functions. These
                        companies may use cookie-based information (this does not including your name, address, email
                        address or telephone number) about your visits to our websites in order to measure advertising
                        effectiveness and your use of our websites to improve service.
                    </Copy>
                    <Copy>
                        These cookies are usually supplied by our business partners and help us to filter out irrelevant
                        messages or pop-ups and to make sure that we only send you information that's interesting and
                        relevant to you. We also use some non-essential cookies for analytical purposes.
                    </Copy>
                    <H3>Marketing Cookies</H3>
                    <Copy>
                        These cookies help us tailor the advertisements you see on third party sites by understanding
                        what interests you on our sites, such as the pages you view. We don’t combine this information
                        with other personal information you provide us with. Cookies may also be used to provide you
                        with information or offers from Marmalade and/or third parties that may be of interest to you.
                    </Copy>
                    <H3>Embedded content</H3>
                    <Copy>
                        We sometimes embed photos, video and other content from websites such as Twitter and YouTube. As
                        a result, when you visit a page with content embedded from, for example, Twitter and YouTube,
                        you may be presented with cookies from these websites. The Marmalade websites can carry embedded
                        ‘share’ buttons to enable users of the site to easily share articles with their friends through
                        a number of popular social networks. These sites may set a cookie when you are also logged in to
                        their service. Marmalade does not control the dissemination of these cookies. You should check
                        the relevant third-party website for more information about these.
                    </Copy>
                    <H3>Updating your cookie preferences</H3>
                    <Copy>
                        When you first access our website you will be asked to choose your cookie preferences. You can
                        change these settings at any time by clicking on the “Change cookie settings” link in our
                        website footer.
                    </Copy>
                    <Copy>
                        You can block any cookies from any website through your browser settings. If you share the use
                        of a computer, accepting or rejecting the use of cookies will affect all users of that computer.
                    </Copy>
                    <H3 id="cookie-list">Cookie list</H3>
                    <Copy>
                        Cookie name: <strong>_gat _ga _gid</strong>
                    </Copy>
                    <Copy>Cookie Provider: Google Analytics</Copy>
                    <Copy>
                        Purpose: These cookies are used to collect information about how visitors use our website. We
                        use the information to compile reports and help us to improve our website. The cookies collect
                        information in a way that does not directly identify anyone.
                    </Copy>
                </Container>
            </Section>
        </Layout>
    );
}

export default withTheme(CookiePolicy);
