import FeatureLink from '@marmalade/bling/lib/feature-link';
import { H2, H3 } from '@marmalade/bling/lib/headers/default';
import { withTheme } from 'glamorous';
import { object } from 'prop-types';
import React from 'react';
import htmlTemplateDoc from '../../../media/emails/BrokerGenericTemplate.txt';
import userGuideDoc from '../../../media/emails/BrokerGenericTemplateHTMLUserGuide.docx';
import wordTemplateDoc from '../../../media/emails/BrokerGenericTemplateWord.docx';
import htmlTemplate from '../../../media/emails/HTMLTemplate.png';
import userGuide from '../../../media/emails/TemplateUserGuide.png';
import wordTemplate from '../../../media/emails/WordTemplate.png';
import PageWrapper from '../../components/page-wrapper';

Emails.propTypes = {
    theme: object.isRequired,
};

function Emails({ theme }) {
    const featureLinkProps = {
        target: '_blank',
        css: {
            width: '33.3%',
            marginBottom: theme.space_lg,
            paddingRight: theme.space_xs,
            paddingLeft: theme.space_xs,
        },
        imgCss: { border: theme.border },
        iconType: 'download',
    };

    return (
        <PageWrapper>
            <H2>Email Templates</H2>
            <H3>Download email templates</H3>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexFlow: 'row wrap',
                    marginLeft: `-${theme.space_xs}`,
                    marginRight: `-${theme.space_xs}`,
                }}
            >
                <FeatureLink
                    title="Template User Guide"
                    alt="Template User Guide"
                    download="Template User Guide.docx"
                    imgSrc={userGuide}
                    href={userGuideDoc}
                    {...featureLinkProps}
                />
                <FeatureLink
                    title="Word Document Template"
                    alt="Word Document Template"
                    download="Word Document Template.docx"
                    imgSrc={wordTemplate}
                    href={wordTemplateDoc}
                    {...featureLinkProps}
                />
                <FeatureLink
                    title="HTML Template"
                    alt="HTML Template"
                    download="HTML Template.html"
                    imgSrc={htmlTemplate}
                    href={htmlTemplateDoc}
                    {...featureLinkProps}
                />
            </div>
        </PageWrapper>
    );
}

export default withTheme(Emails);
