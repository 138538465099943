import * as authConstants from '@marmalade/account/lib/constants';
import accountReducer from '@marmalade/account/lib/reducer';
import { NAME as fdiName } from '@marmalade/fdi/lib/quick-quote/constants';
import fdiReducer from '@marmalade/fdi/lib/quick-quote/reducer';
import { NAME as formName } from '@marmalade/form/lib/constants';
import formsReducer from '@marmalade/form/lib/reducer';
import { NAME as helpInfoName, reducer as helpInfoReducer } from '@marmalade/help-info';
import { NAME as internalName, reducer as internalReducer } from '@marmalade/is-internal';
import ldiReducers from '@marmalade/ldi/lib/all-reducers';
import { NAME as modalName } from '@marmalade/modal/lib/constants';
import modalReducer from '@marmalade/modal/lib/reducer';
import { NAME as multiName } from '@marmalade/multi-quote/lib/quick-quote/constants';
import multiReducer from '@marmalade/multi-quote/lib/quick-quote/reducer';
import { NAME as ndiName } from '@marmalade/ndi/lib/quick-quote/constants';
import ndiReducer from '@marmalade/ndi/lib/quick-quote/reducer';
import * as queryConstants from '@marmalade/query/lib/constants';
import queryReducer from '@marmalade/query/lib/reducer';
import { combineReducers } from 'redux';
import reduxWindow from 'redux-window';
import { reducer as assignEmailReducer } from './admin/modules/email-madness';
import { NAME as assignEmailName } from './admin/modules/email-madness/constants';
import logoReducer, { NAME as logoName } from './admin/modules/marketing/posters/save-logo';
import { NAME as requestName } from './admin/modules/quick-quotes/proceed-modal/constants';
import requestReducer from './admin/modules/quick-quotes/proceed-modal/reducer';

export default combineReducers({
    broker: () => true,
    reduxWindow,
    [formName]: formsReducer,
    [modalName]: modalReducer,
    [helpInfoName]: helpInfoReducer,
    [authConstants.NAME]: accountReducer,
    [queryConstants.NAME]: queryReducer,
    [internalName]: internalReducer,
    [helpInfoName]: helpInfoReducer,
    ...ldiReducers,
    [fdiName]: fdiReducer,
    [ndiName]: ndiReducer,
    [multiName]: multiReducer,
    [requestName]: requestReducer,
    [logoName]: logoReducer,
    [assignEmailName]: assignEmailReducer,
});
