import { Container } from '@marmalade/grid';
import { Div, P, withTheme } from 'glamorous';
import { object } from 'prop-types';
import React from 'react';
import { H1 } from '../../../components/typography';
import Hero from '../../../marketing/components/hero';
import heroBackground from '../../../marketing/media/hero.svg';

RegisterHero.propTypes = {
    theme: object.isRequired,
};

function RegisterHero({ theme }) {
    return (
        <Hero
            background={`url(${heroBackground}) no-repeat center center`}
            color={theme.colorOffWhite}
            angle={2.6}
            overflow="35rem"
        >
            <Div paddingTop="4rem" paddingBottom="32rem" textAlign="center">
                <Container>
                    <H1>We're really looking forward to working with you</H1>
                    <P fontSize="2rem">
                        Having access to Marmalade’s young driver products can help you to acquire and cover customers
                        from the very start of their driving journey from using the family car – to getting insured on
                        their own car!
                    </P>
                </Container>
            </Div>
        </Hero>
    );
}

export default withTheme(RegisterHero);
