import React from 'react';
import ProductPage from '../../../marketing/components/product-page';
import background from '../../../marketing/media/ndi-hero.jpg';
import ndiProductImage from '../../../marketing/media/ndi-product-image.jpg';

import { testimonials } from '../constants';

function NDI() {
    return (
        <ProductPage
            title="Affordable black box insurance for young drivers aged 17- 30"
            biba
            image={background}
            caveats={[
                <small key="national-statitics">
                    * based on Marmalade Black Box Insurance (your car) policies incepted 1st April 2016 – 31st March
                    2017. 1 in 15 Marmalade drivers have an accident within 6 months of their policy start date,
                    compared with 1 in 5 young drivers who have an accident in the first 6 months after passing their
                    test, according to National Statistics.
                </small>,
            ]}
            sections={[
                {
                    type: 'lead',
                    features: [
                        {
                            title: 'Earn up to £75 per policy',
                            subtitle: '(dependent on volume)',
                            body:
                                'You can earn 7.5% of the premium or £50-£75 per new policy sold, whichever is lower.',
                            icon: 'pound-sign',
                        },
                        {
                            title: 'Instant quote',
                            body: 'Give a customer a quick quote in less than a minute, and offer instant cover.',
                            icon: 'clock',
                        },
                        {
                            title: 'Friendly broker team',
                            body: 'Our dedicated support team is on hand to help with any questions.',
                            icon: 'customer-service',
                        },
                    ],
                },
                {
                    type: 'register-cta',
                    title: 'What is Black Box Insurance?',
                    subtitle:
                        'Marmalade’s Black Box Insurance offers young drivers cover on their own car, before and after they pass their test.',
                    features: [
                        'The price won’t go up when they pass',
                        'No curfews – drive 24/7',
                        'They build their own No Claims Discount',
                        'App to view journeys instantly',
                    ],
                    media: (
                        <img
                            src={ndiProductImage}
                            alt="Young man in the driver seat leaning out of window with thumbs up"
                            width="490px"
                            height="290px"
                        />
                    ),
                },
                { type: 'young-driver-reviews' },
                {
                    type: 'register-cta',
                    title: 'Why offer Marmalade Black Box Insurance?',
                    subtitle: 'Our new drivers are three times safer on the road, than the national average*',
                    reverse: true,
                    features: [
                        'Insurance designed specifically with young driver needs in mind',
                        'Easy to use portal to offer quotes',
                        'Free marketing material',
                        'Support available from our friendly broker team',
                    ],
                },
                {
                    type: 'customer-testimonials',
                    testimonials,
                },
            ]}
        />
    );
}

export default NDI;
