import { withTheme } from 'glamorous';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getWidth } from 'redux-window';
import { isMobile, isTablet } from '../../../selectors';

import HeaderWrapper from '@marmalade/headers/lib/header-wrapper';

import bar from './bar';
import menu from './menu';

Header.propTypes = {
    options: arrayOf(
        shape({
            to: string,
            text: string,
        })
    ),
    onLogin: func,
    tablet: bool,
    mobile: bool,
    width: number,
};

function Header({ options, onLogin, tablet, mobile, width }) {
    return (
        <HeaderWrapper
            sections={[
                { key: 'bar', render: bar({ onLogin, tablet, width }) },
                {
                    key: 'test',
                    render: menu({ tablet, mobile, onLogin, options }),
                },
            ]}
        />
    );
}

export default withTheme(
    connect((state, { theme }) => ({
        width: getWidth(state),
        tablet: isTablet(theme)(state),
        mobile: isMobile(theme)(state),
    }))(Header)
);
