import React from 'react';
import { VEHICLE_CAVEATS } from '../../../constants';
import ProductPage from '../../../marketing/components/product-page';
import background from '../../../marketing/media/nydi-hero.jpg';
import nydiProductImage from '../../../marketing/media/nydi-product-image.jpg';
import nydiPlaceholderImage from '../../../marketing/media/video-placeholders/nydi-placeholder.jpg';
import { testimonials } from '../constants';

function NYDI() {
    return (
        <ProductPage
            title="Affordable alternative to being added as a named driver on a parent’s car"
            image={background}
            sections={[
                {
                    type: 'lead',
                    features: [
                        {
                            title: 'Earn up to £100 per policy',
                            body: 'Depending on the type of policy purchased, you can earn up to £100.',
                            icon: 'pound-sign',
                        },
                        {
                            title: 'Instant quote',
                            body: 'Give a customer a quick quote in less than a minute, and we’ll do the rest!',
                            icon: 'clock',
                        },
                        {
                            title: 'Friendly broker team',
                            body: 'Our dedicated support team is on hand to help with any questions.',
                            icon: 'customer-service',
                        },
                    ],
                },
                {
                    type: 'register-cta',
                    title: 'What is Named Young Driver Insurance?',
                    subtitle:
                        'Named Young Driver Insurance gives the freedom for a young driver to drive a parents’ car on a fully comprehensive policy.',
                    features: [
                        'No risk to parents’ No Claims Discount',
                        'Young driver builds their own No Claims Discount',
                        'Cover full and provisional licence holders aged 17-27',
                        VEHICLE_CAVEATS,
                        'Marmalade only review the young driver’s journeys',
                    ],
                    media: (
                        <img
                            src={nydiProductImage}
                            alt="Young man in the driving seat of his parent's car with his mum sitting next to him"
                            width="490px"
                            height="290px"
                        />
                    ),
                },
                { type: 'young-driver-reviews' },
                {
                    type: 'register-cta',
                    title: 'Why offer Marmalade Named Young Driver Insurance?',
                    subtitle:
                        'A solution for young drivers to drive the family car before and after they pass their driving test.',
                    reverse: true,
                    features: [
                        'Grow business for young drivers looking to use a friend or family member’s car',
                        'Young driver specialists',
                        'Easy to use portal to offer quotes',
                        'Free marketing material',
                    ],
                    media: (
                        <img
                            src={nydiPlaceholderImage}
                            alt="Marmalade: Secrets of the black box"
                            width="490px"
                            height="290px"
                        />
                    ),
                },
                {
                    type: 'customer-testimonials',
                    testimonials,
                },
            ]}
        />
    );
}

export default NYDI;
