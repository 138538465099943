import { Container } from '@marmalade/grid';
import glamorous, { A, Div, withTheme } from 'glamorous';
import { arrayOf, bool, node } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import bibaImage from '../../marketing/media/biba-white.png';
import { isMobile } from '../../selectors';

// NOTES

/**
 * Remove all hard coded color codes
 */

const footerStyles = ({ theme }) => ({
    backgroundColor: theme.colorBlack,
    color: theme.colorWhite,
    '& p': {
        fontSize: '1.4rem',
    },
    '& small': {
        fontSize: '1.2rem',
    },
    '& a': {
        color: theme.colorWhite,
        textDecoration: 'underline',
        '&:hover:not(.disabled)': {
            color: theme.colorWhite,
        },
    },
});

const Footer = glamorous.footer(footerStyles);

const dividerStyles = () => ({
    backgroundColor: '#23221E',
    height: 1,
    marginTop: '3rem',
    marginBottom: '3rem',
});

const Divider = glamorous.div(dividerStyles);

B2BFooter.propTypes = {
    caveats: arrayOf(node),
    copy: node,
    mobile: bool,
};

function B2BFooter({
    caveats = [],
    copy = (
        <Fragment>
            <Div fontSize="1.4rem">
                <A href="/cookie-policy" marginRight="1.4rem">
                    Cookie policy
                </A>
                {/* <Span
                    onClick={() => window && typeof window.openCookiePrefs === 'function' && window.openCookiePrefs()}
                    cursor="pointer"
                >
                    Change cookie settings
                </Span> */}
            </Div>
            <br />
            <p>&copy; {new Date().getFullYear()} Marmalade All Rights Reserved.</p>
            <p>
                Marmalade is a trading name of Atlanta Insurance Intermediaries Limited. Authorised and Regulated by the
                Financial Conduct Authority under firm reference number 309599. Company registration number: 756681.
                Registered address: Embankment West Tower, 101 Cathedral Approach, Salford, M3 7FB.
                <br />
                Atlanta Insurance Intermediaries Limited can be found on the Financial Services Register under reference
                number 309599. For more information, please contact us.
                <br />
                Calls are recorded for training and quality purposes.
            </p>
        </Fragment>
    ),
    mobile = false,
}) {
    return (
        <Footer>
            <Container>
                <Div display="flex" flexDirection={mobile ? 'column' : 'row'} marginTop="4rem" marginBottom="4rem">
                    <div>
                        {caveats.length ? (
                            <Fragment>
                                {caveats}
                                <Divider />
                            </Fragment>
                        ) : null}
                        {copy}
                    </div>
                    <Div flexShrink={0}>
                        <img src={bibaImage} alt="BIBA" width="auto" />
                    </Div>
                </Div>
            </Container>
        </Footer>
    );
}

export default withTheme(
    connect((state, { theme }) => ({
        mobile: isMobile(theme)(state),
    }))(B2BFooter)
);
