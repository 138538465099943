import { LinkButton } from '@marmalade/button';
import { Container } from '@marmalade/grid';
import { between } from '@marmalade/themes';
import glamorous, { Div, H3, H4, Section, withTheme } from 'glamorous';
import { arrayOf, bool, node, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import BulletList from '../../../marketing/components/bullet-list';
import { isMobile } from '../../../selectors';

const copyStyles = ({ theme, children, ...rest }) => ({
    color: theme.colorGrey,
    fontSize: '2.3rem',
    marginBottom: '3rem',
    ...rest,
});

const Copy = glamorous.p(copyStyles);

const contentStyles = ({ theme, reverse }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: reverse ? 'row-reverse' : undefined,
    [between(0, theme.breakpoint_sm)]: {
        flexDirection: 'column',
    },
});

const Content = glamorous.div(contentStyles);

const sideStyles = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '1.8rem',
    maxWidth: '50%',
    [between(0, theme.breakpoint_sm)]: {
        maxWidth: '100%',
    },
});

const Side = glamorous.div(sideStyles);

const spacerStyles = ({ theme, space }) => ({
    marginBottom: -space,
    [between(0, theme.breakpoint_sm)]: {
        marginBottom: 0,
    },
});

const Spacer = glamorous.div(spacerStyles);

const registerButtonContainerStyles = ({ theme }) => ({
    marginLeft: '1.8rem',
    width: '18rem',
    display: 'flex',
    flexDirection: 'column',
    [between(0, theme.breakpoint_sm)]: {
        width: '100%',
        alignItems: 'center',
        '& > a': {
            width: '18rem',
        },
    },
});

const RegisterButtonContainer = glamorous.div(registerButtonContainerStyles);

RegisterCTA.propTypes = {
    reverse: bool,
    media: node.isRequired,
    title: string,
    subtitle: string,
    features: arrayOf(string),
    backgroundColor: string,
    mobile: bool.isRequired,
};

function RegisterCTA({ reverse = false, media, title, subtitle, features, backgroundColor, mobile }) {
    return (
        <Section backgroundColor={backgroundColor}>
            <Container>
                <Div textAlign="center" marginTop="6rem" marginBottom="5rem">
                    <H3 fontSize="2.8rem">{title}</H3>
                    <Copy>{subtitle}</Copy>
                    <Content reverse={reverse}>
                        <Div flexGrow={1} maxWidth={(290 * 16) / 9}>
                            {media}
                        </Div>
                        <Side>
                            <Spacer space={reverse ? 18 : 5} />
                            {!reverse && (
                                <H4 fontSize="2rem" marginLeft={mobile ? undefined : '1.8rem'} marginBottom={0}>
                                    Benefits to your customer include:
                                </H4>
                            )}
                            <BulletList primary color="#5c6770" marginLeft={mobile ? undefined : '1.8rem'}>
                                {Li => features.map((feature, i) => <Li key={`${i}`}>{feature}</Li>)}
                            </BulletList>
                            <RegisterButtonContainer>
                                <LinkButton to="/register" prominence="primary">
                                    Register
                                </LinkButton>
                            </RegisterButtonContainer>
                        </Side>
                    </Content>
                </Div>
            </Container>
        </Section>
    );
}

export default withTheme(
    connect((state, { theme }) => ({
        mobile: isMobile(theme)(state),
    }))(RegisterCTA)
);
