import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../../../marketing/pages/404';
import Products from './index';
import LDIPage from './ldi';
import NDIPage from './ndi';
import NYDIPage from './nydi';

function Router() {
    return (
        <Switch>
            <Route exact path="/products" component={Products} />
            <Route exact path="/products/ldi" component={LDIPage} />
            <Route exact path="/products/nydi" component={NYDIPage} />
            <Route exact path="/products/ndi" component={NDIPage} />
            <Route component={PageNotFound} />
        </Switch>
    );
}

export default Router;
