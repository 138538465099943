import Button, { ButtonGroup } from '@marmalade/button';
import domain from '@marmalade/domain';
import { func, object, string } from 'prop-types';
import React from 'react';
import fdiBrokerInsert from '../../../../../media/inserts/fdi-insert.pdf';
import Caveats from '../../named/caveats';
import { USPListAnnual } from '../../named/get-a-quote';
import AnnualOrMonthlyResults from './annual-or-monthly-results';
import ResultsPanel from './results-panel';

FDIResults.propTypes = {
    prices: object.isRequired,
    openModal: func.isRequired,
    quoteRef: string.isRequired,
    guid: string.isRequired,
};

function FDIResults({ prices, openModal, guid, quoteRef }) {
    return (
        <ResultsPanel
            title="Named Young Driver Annual Insurance"
            paragraph="Annual black box insurance on a parent's car allowing the young driver to drive up to 10,000 miles per year, on a provisional and full licence."
            buttons={
                <ButtonGroup>
                    <Button
                        Element="a"
                        href={`${domain('familycar.wearemarmalade')}/fdi/new/stage1?quoteRef=${quoteRef}&guid=${guid}`}
                        target="_blank"
                        prominence="primary"
                    >
                        Proceed to full quote
                    </Button>
                    <Button text="Request call back" prominence="primary" onClick={openModal} />
                </ButtonGroup>
            }
            list={USPListAnnual}
            brokerInsert={fdiBrokerInsert}
            caveat={<Caveats />}
        >
            <AnnualOrMonthlyResults {...prices[10000]} />
        </ResultsPanel>
    );
}

export default FDIResults;
