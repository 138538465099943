import { Container } from '@marmalade/grid';
import { Div, H1, H2, H3 } from 'glamorous';
import React from 'react';
import { Link } from 'react-router-dom';
import Hero from '../../marketing/components/hero';
import Layout from '../../marketing/components/layout';
import heroBackground from '../../marketing/media/hero.svg';

function PageNotFound() {
    return (
        <Layout>
            <Hero background={`url(${heroBackground}) no-repeat center center`} color="#fff" angle={2.6}>
                <Container>
                    <H1 fontWeight={900} fontSize="4.6rem">
                        404
                    </H1>
                    <H2>Page not found</H2>
                </Container>
            </Hero>
            <Container>
                <H3>We couldn't find what you are looking for.</H3>
                <Link to="/">Home</Link>
                <Div height="4rem" />
            </Container>
        </Layout>
    );
}

export default PageNotFound;
