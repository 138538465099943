import { Select } from '@marmalade/accesibility';
import PromoPanel from '@marmalade/bling/lib/b2b/promo-panel';
import { Container } from '@marmalade/grid';
import { showModal } from '@marmalade/modal/lib/actions';
import { Div, H2, H3, Main, P, Section, withTheme } from 'glamorous';
import { func, number, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getWidth } from 'redux-window';
import Testimonials from '../../../marketing/components/customer-testimonials';
import Grid from '../../../marketing/components/grid';
import Hero from '../../../marketing/components/hero';
import Layout from '../../../marketing/components/layout';
import commissionImage from '../../../marketing/media/commissions.jpg';
import heroBackground from '../../../marketing/media/hero.svg';
import ldiImage from '../../../marketing/media/ldi-product-image.jpg';
import ndiImage from '../../../marketing/media/ndi-product-image.jpg';
import nydImage from '../../../marketing/media/nydi-product-image.jpg';
import { getBreakpoint } from '../../../modules/get-breakpoint';
import { Head } from '../../../modules/meta';
import { partners, partnerView, testimonials } from '../constants';
import HeroContent from './hero-content';

const showModalOnClick = (showModal, modal) => () => {
    showModal(modal);
};

function rows(width, theme) {
    switch (getBreakpoint(width, theme)) {
        case 'xs':
        case 'sm':
            return 1;
        case 'md':
        case 'lg':
            return 2;
        default:
            return 3;
    }
}

function partnerRows(width, theme) {
    switch (getBreakpoint(width, theme)) {
        case 'xs':
        case 'sm':
            return 2;
        case 'md':
        case 'lg':
            return 3;
        default:
            return 6;
    }
}

const data = showModal => [
    {
        image: {
            src: ldiImage,
            alt: 'Young lady learner driver leaning out of car window with thumbs up',
        },
        heading: 'Learner Driver Insurance',
        body: 'Temporary learner insurance on a family or friend’s car.',
        link: '/products/ldi',
    },
    {
        image: {
            src: nydImage,
            alt: "Young man in the driving seat of his parent's car with his mum sitting next to him",
        },
        heading: 'Named Young Driver Insurance',
        body: 'Alternative to adding a young named driver on a parent’s car.',
        link: '/products/nydi',
    },
    {
        image: {
            src: ndiImage,
            alt: 'Young man in the driver seat leaning out of window with thumbs up',
        },
        heading: 'Black Box Insurance',
        body: 'Annual cover for young drivers on their own car.',
        link: '/products/ndi',
    },
    {
        image: {
            src: commissionImage,
            alt: 'Close up of keyboard with enter key replaced with pound symbol',
        },
        heading: 'Start earning commissions',
        body: (
            <span>
                One portal for all our products.{' '}
                <a
                    onClick={showModalOnClick(showModal, 'login')}
                    role="button"
                    tabIndex={0}
                    onKeyDown={Select(showModalOnClick(showModal, 'login'))}
                >
                    Login
                </a>{' '}
                or <Link to="/register">register</Link> today!
            </span>
        ),
    },
];

class Home extends Component {
    static propTypes = {
        width: number.isRequired,
        theme: object.isRequired,
        showModal: func.isRequired,
    };
    state = { open: false };
    toggleMenu = () => {
        this.setState({ open: !this.state.open });
    };
    render() {
        const { width, theme, showModal } = this.props;
        return (
            <Layout>
                <Head title="Marmalade Broker Dashboard" />
                <Hero background={`url(${heroBackground}) no-repeat center center`} color="#f9f9f9" angle={2.6}>
                    <Container>
                        <HeroContent />
                    </Container>
                </Hero>
                <Main backgroundColor="#f9f9f9" paddingBottom="5rem" paddingTop="5.5rem">
                    <Container>
                        <Grid rows={rows(width, theme)}>
                            {wrapper =>
                                data(showModal).map((panel, i) =>
                                    wrapper(
                                        <PromoPanel image={panel.image}>
                                            <Div display="flex" flexDirection="column" height="12rem" flexShrink={1}>
                                                <Div>
                                                    <H3 fontSize="2rem" marginBottom="1rem">
                                                        {panel.heading}
                                                    </H3>
                                                    <P color="#5c6770" marginBottom="0">
                                                        {panel.body}
                                                    </P>
                                                </Div>
                                                {panel.link && (
                                                    <Div>
                                                        <Link to={panel.link}>Find out more</Link>
                                                    </Div>
                                                )}
                                            </Div>
                                        </PromoPanel>,
                                        i
                                    )
                                )
                            }
                        </Grid>
                    </Container>
                </Main>
                <Section>
                    <Testimonials testimonials={testimonials} />
                </Section>
                <Section backgroundColor="#f9f9f9">
                    <Container>
                        <Div
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            padding={width < theme.breakpoint_sm ? '3rem 0' : '6rem 0'}
                        >
                            <H2>Our partners</H2>
                            <Grid rows={partnerRows(width, theme)} maxWidth={partnerRows(width, theme) * 175 + 20}>
                                {wrapper =>
                                    partners.map((partner, i) =>
                                        wrapper(
                                            <Div
                                                border={partnerView.border}
                                                width="17.5rem"
                                                maxWidth="17.5rem"
                                                height="10rem"
                                                backgroundColor={partner.bg}
                                                backgroundImage={`url(${partner.src})`}
                                                backgroundSize="contain"
                                                backgroundPosition="center"
                                                backgroundRepeat="no-repeat"
                                            />,
                                            `${i}`
                                        )
                                    )
                                }
                            </Grid>
                        </Div>
                    </Container>
                </Section>
            </Layout>
        );
    }
}

export default withTheme(
    connect(
        state => ({
            width: getWidth(state),
        }),
        { showModal }
    )(Home)
);
