import Button, { ButtonGroup } from '@marmalade/button';
import domain from '@marmalade/domain';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import ndiBrokerInsert from '../../../../../media/inserts/ndi-insert.pdf';
import { AnnualCaveats } from '../../black-box/caveats';
import { USPList as ndiList } from '../../black-box/get-a-quote';
import AnnualOrMonthlyResults from './annual-or-monthly-results';
import ResultsPanel from './results-panel';

NDIResults.propTypes = {
    prices: object.isRequired,
    openModal: func.isRequired,
    quoteRef: string.isRequired,
    creditBroker: bool.isRequired,
    guid: string.isRequired,
};

function NDIResults({ prices, openModal, quoteRef, creditBroker, guid }) {
    return (
        <ResultsPanel
            title="Black Box Insurance"
            paragraph="Comprehensive cover on a young driver's own car, before and after they pass their test."
            buttons={
                creditBroker ? (
                    <ButtonGroup>
                        <Button
                            Element="a"
                            href={`${domain('newdriver.wearemarmalade')}/broker/login?quoteref=${quoteRef}`}
                            target="_blank"
                            prominence="primary"
                        >
                            Proceed to full quote
                        </Button>
                    </ButtonGroup>
                ) : (
                    <ButtonGroup>
                        <Button
                            Element="a"
                            href={`${domain('newdriver.wearemarmalade')}/new/stage1?quoteref=${quoteRef}&guid=${guid}`}
                            target="_blank"
                            prominence="primary"
                        >
                            Proceed to full quote
                        </Button>

                        <Button
                            text="Request call back"
                            prominence="primary"
                            onClick={() =>
                                openModal('callback', undefined, {
                                    product: 'ndi',
                                    quoteRef,
                                })
                            }
                        />
                    </ButtonGroup>
                )
            }
            list={ndiList}
            product="ndi"
            quoteRef={quoteRef}
            brokerInsert={ndiBrokerInsert}
            caveat={<AnnualCaveats />}
        >
            <AnnualOrMonthlyResults {...prices} />
        </ResultsPanel>
    );
}

export default NDIResults;
