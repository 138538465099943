import { constants as contact } from '@marmalade/contact';
import { Container } from '@marmalade/grid';
import Icon, { primaryIconComponent } from '@marmalade/icon';
import { Panel } from '@marmalade/panel';
import { between } from '@marmalade/themes';
import componentThemes from '@marmalade/themes/lib/component-base-themes';
import glamorous, { A, Div, P, Section, ThemeProvider, withTheme } from 'glamorous';
import { bool, object } from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { H1, H3 } from '../../../components/typography';
import Layout from '../../../marketing/components/layout';
import { isMobile, isTablet } from '../../../selectors';
import Form from './form';

const copyStyles = ({ theme }) => ({
    color: theme.colorGrey,
    '& a': {
        fontWeight: 700,
    },
});

const Copy = glamorous.p(copyStyles);

const contentContainerStyles = ({ theme }) => ({
    display: 'flex',
    '& div:first-of-type': {
        marginRight: '1rem',
    },
    [between(0, theme.breakpoint_lg)]: {
        flexDirection: 'column',
        '& div:first-of-type': {
            marginRight: 0,
        },
    },
});

const panelStyleThingy = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [between(theme.breakpoint_sm, theme.breakpoint_lg)]: {
        flexDirection: 'row',
    },
    [between(0, theme.breakpoint_sm)]: {
        flexDirection: 'column',
    },
});

const PanelStyleThingy = glamorous.div(panelStyleThingy);

const ContentContainer = glamorous.div(contentContainerStyles);

const SmallPrimaryIcon = primaryIconComponent('3rem');

const iconLinkWrapperStyles = ({ theme }) => ({
    margin: theme.space_xs,
    color: theme.colorBlack,
    transition: 'ease-in-out 0.2s',
});

const IconLinkWrapper = glamorous.a(iconLinkWrapperStyles);

ContactUs.propTypes = {
    mobile: bool.isRequired,
    tablet: bool.isRequired,
    theme: object.isRequired,
};

function ContactUs({ mobile, tablet }) {
    const { controlStyle } = componentThemes.form;
    let headingPadding = '6rem';
    if (tablet) {
        headingPadding = '2rem';
    }
    if (mobile) {
        headingPadding = '1rem';
    }
    return (
        <ThemeProvider theme={{ [controlStyle('backgroundColor')]: '#fff' }}>
            <Layout>
                <Container>
                    <Section paddingTop={headingPadding}>
                        <H1>We're here to help!</H1>
                        <Copy>
                            The broker team are on hand to assist and support with any young driver enquiry and help you
                            increase your share of the young driver market. Simply pick up the phone and give us a call
                            on <a href={`tel:${contact.phone.broker.split(' ').join('')}`}>{contact.phone.broker}</a> or
                            send us a message.
                        </Copy>
                        <ContentContainer>
                            <Div flexGrow={1}>
                                <Panel>
                                    <H3>Send us a message</H3>
                                    <Form />
                                </Panel>
                            </Div>
                            <Div>
                                <Panel>
                                    <PanelStyleThingy>
                                        <Div flexGrow={1}>
                                            <H3 marginBottom="1.5rem">Address</H3>
                                            <Div display="flex">
                                                <SmallPrimaryIcon type="pin" />
                                                <P marginLeft="1rem">
                                                    {contact.address.map((addrLine, i) => (
                                                        <Fragment key={i}>
                                                            {addrLine}
                                                            <br />
                                                        </Fragment>
                                                    ))}
                                                </P>
                                            </Div>
                                            <H3 marginBottom="1.5rem">Phone</H3>
                                            <Div display="flex">
                                                <SmallPrimaryIcon type="cordless" />
                                                <Div marginLeft="1rem">
                                                    <P>{contact.phone.broker}</P>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div flexGrow={1}>
                                            <H3 marginBottom="1.5rem">Opening hours</H3>
                                            <Div display="flex">
                                                <SmallPrimaryIcon type="clock" />
                                                <P marginLeft="1rem">
                                                    {[
                                                        'Monday: 9:00 - 17:30',
                                                        'Tuesday: 9:00 - 17:30',
                                                        'Wednesday: 9:00 - 17:30',
                                                        'Thursday: 9.00 - 17:30',
                                                        'Friday: 9:00 - 17:30',
                                                    ].map((days, i) => (
                                                        <Fragment key={i}>
                                                            {days}
                                                            <br />
                                                        </Fragment>
                                                    ))}
                                                </P>
                                            </Div>
                                            <H3 marginBottom="1.5rem">Email</H3>
                                            <Div display="flex">
                                                <SmallPrimaryIcon type="envelope" />
                                                <A href={`mailto:${contact.email.broker}`} marginLeft="1rem">
                                                    {contact.email.broker}
                                                </A>
                                            </Div>
                                            <H3 marginBottom=".5rem" marginTop="1.5rem">
                                                Follow us
                                            </H3>
                                            <Div fontSize="4rem">
                                                <IconLinkWrapper
                                                    href={`https://twitter.com/${contact.social.twitter}`}
                                                    target="_blank"
                                                >
                                                    <Icon type="circle-social-twitter" />
                                                </IconLinkWrapper>
                                                <IconLinkWrapper
                                                    href={`https://www.facebook.com/${contact.social.facebook}`}
                                                    target="_blank"
                                                >
                                                    <Icon type="circle-social-fb" />
                                                </IconLinkWrapper>
                                                <IconLinkWrapper
                                                    href={`https://www.youtube.com/user/${contact.social.youtube}`}
                                                    target="_black"
                                                >
                                                    <Icon type="circle-social-youtube-play" />
                                                </IconLinkWrapper>
                                            </Div>
                                        </Div>
                                    </PanelStyleThingy>
                                </Panel>
                            </Div>
                        </ContentContainer>
                    </Section>
                </Container>
            </Layout>
        </ThemeProvider>
    );
}

export default withTheme(
    connect((state, { theme }) => ({
        mobile: isMobile(theme)(state),
        tablet: isTablet(theme)(state),
    }))(ContactUs)
);
