import PromoPanel from '@marmalade/bling/lib/b2b/promo-panel';
import { LinkButton } from '@marmalade/button';
import { Container } from '@marmalade/grid';
import { Div, P, Section, withTheme } from 'glamorous';
import { number, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getWidth } from 'redux-window';
import { H1, H3 } from '../../../components/typography';
import BulletList from '../../../marketing/components/bullet-list';
import Grid from '../../../marketing/components/grid';
import Hero from '../../../marketing/components/hero';
import Layout from '../../../marketing/components/layout';
import heroBackground from '../../../marketing/media/hero.svg';
import ldiImage from '../../../marketing/media/ldi-product-image.jpg';
import ndiImage from '../../../marketing/media/ndi-product-image.jpg';
import nydImage from '../../../marketing/media/nydi-product-image.jpg';
import { getBreakpoint } from '../../../modules/get-breakpoint';

function rows(width, theme) {
    switch (getBreakpoint(width, theme)) {
        case 'xs':
        case 'sm':
            return 1;
        case 'md':
        case 'lg':
        default:
            return 2;
    }
}

function centerText(width, theme) {
    switch (getBreakpoint(width, theme)) {
        case 'xs':
        case 'sm':
            return true;
        case 'md':
        case 'lg':
        default:
            return false;
    }
}

const data = [
    {
        image: {
            src: ldiImage,
            alt: 'Young lady learner driver leaning out of car window with thumbs up',
        },
        heading: 'Learner Driver Insurance',
        features: [
            '30 day to 180 day cover options',
            'Enables practice in a friend’s or parent’s car',
            'Comprehensive cover at competitive prices',
        ],
        link: '/products/ldi',
    },
    {
        image: {
            src: nydImage,
            alt: "Young man in the driving seat of his parent's car with his mum sitting next to him",
        },
        heading: 'Named Young Driver Insurance',
        features: [
            'For full and provisional licence holders',
            'Alternative to being added as a named driver',
            'No risk to parents’ No Claims Discount',
        ],
        link: '/products/nydi',
    },
    {
        image: {
            src: ndiImage,
            alt: 'Young man in the driver seat leaning out of window with thumbs up',
        },
        heading: 'Black Box Insurance',
        features: [
            'Comprehensive cover on a young driver’s own car',
            'For full or provisional licence holders',
            'Black box helps young drivers to develop safe driving skills',
        ],
        link: '/products/ndi',
    },
];

Products.propTypes = {
    width: number.isRequired,
    theme: object.isRequired,
};

function Products({ width, theme }) {
    return (
        <Layout backgroundColor="#f9f9f9">
            <Hero
                background={`url(${heroBackground}) no-repeat center center`}
                color="#f9f9f9"
                angle={2.6}
                overflow="15rem"
            >
                <Div
                    paddingTop="4rem"
                    paddingBottom="15rem"
                    textAlign={centerText(width, theme) ? 'center' : undefined}
                >
                    <Container>
                        <H1 fontSize="4.5rem" fontWeight="900">
                            Affordable insurance for young drivers
                        </H1>
                        <P fontSize="2rem">
                            Grow your young driver market share with great solutions for parents looking to share the
                            family car, or get their young driver on the road in their own car.{' '}
                            <b>Earn commissions of up to £100 per customer!</b>
                        </P>
                    </Container>
                </Div>
            </Hero>
            <Section paddingBottom="20rem">
                <Container>
                    <Grid rows={rows(width, theme)}>
                        {wrapper =>
                            data.map((panel, i) =>
                                wrapper(
                                    <PromoPanel image={panel.image} imageLink={panel.link}>
                                        <H3 fontSize="2rem" marginBottom="1rem">
                                            {panel.heading}
                                        </H3>
                                        <BulletList primary color="#5c6770">
                                            {Li => panel.features.map((copy, i) => <Li key={i}>{copy}</Li>)}
                                        </BulletList>
                                        <LinkButton prominence="primary" to={panel.link}>
                                            Find out more
                                        </LinkButton>
                                    </PromoPanel>,
                                    i
                                )
                            )
                        }
                    </Grid>
                </Container>
            </Section>
        </Layout>
    );
}

export default withTheme(
    connect(state => ({
        width: getWidth(state),
    }))(Products)
);
