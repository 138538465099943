import { H4 } from '@marmalade/bling/lib/headers/default';
import { Div, withTheme } from 'glamorous';
import { object, string } from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { isTablet } from '../../../../selectors';

BannerAd.propTypes = {
    theme: object.isRequired,
    title: string.isRequired,
    src: object.isRequired,
    html: object.isRequired,
};

function BannerAd({ theme, title, src, html }) {
    return (
        <div>
            <H4>{title}</H4>
            <Ad title={title} theme={theme} src={src.mpu} html={html.mpu} />
            <Ad title={title} theme={theme} src={src.banner} html={html.banner} />
        </div>
    );
}

Ad.propTypes = {
    theme: object.isRequired,
    title: string.isRequired,
    src: string.isRequired,
    html: string.isRequired,
};

function Ad({ theme, src, title, html }) {
    return (
        <Div
            css={{
                backgroundColor: theme.colorOffWhite,
                padding: theme.space_sm,
                marginBottom: theme.space_md,
                display: 'flex',
            }}
        >
            <Div css={{ width: '30%' }}>
                <img src={src} alt={title} style={{ maxHeight: 70, width: 'auto' }} />
            </Div>
            <Div
                css={{
                    paddingLeft: theme.space_sm,
                    paddingRight: theme.space_sm,
                }}
            >
                {html}
            </Div>
            <CopyToClipboard text={html} style={{ width: 80 }}>
                <a>Copy</a>
            </CopyToClipboard>
        </Div>
    );
}

export default withTheme(
    connect((state, ownProps) => ({
        isTablet: isTablet(ownProps.theme)(state),
    }))(BannerAd)
);
