import { location } from '@marmalade/prop-types';
import glamorous, { Div } from 'glamorous';
import { arrayOf, bool, object } from 'prop-types';
import React, { Fragment } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { routeInformation, withSitemap } from '../../modules/meta';

const linkStyles = ({ theme, underlined = false }) => ({
    color: theme.colorBlack,
    textDecoration: underlined ? 'underline' : undefined,
    '&:hover': {
        color: theme.colorBlack,
    },
});

const Link = glamorous(RouterLink, { filterProps: ['underlined'] })(linkStyles, { filterProps: ['underlined'] });

Breadcrumbs.propTypes = {
    location,
    showRoot: bool,
    sitemap: arrayOf(object).isRequired,
};

function Breadcrumbs({ location, showRoot = false, sitemap, ...rest }) {
    const roots = showRoot
        ? routeInformation(location.pathname, sitemap)
        : routeInformation(location.pathname, sitemap).slice(1);
    return (
        <Div {...rest}>
            {roots.map(({ url, name }) => (
                <Fragment key={url}>
                    <Link to={url} underlined={location.pathname === url}>
                        {name}
                    </Link>
                    {location.pathname !== url ? ' > ' : ''}
                </Fragment>
            ))}
        </Div>
    );
}

export default withSitemap(withRouter(Breadcrumbs));
