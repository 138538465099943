import React from 'react';
import brokerInsert from '../../../../media/inserts/fdi-insert.pdf';
import Documents from '../components/documents';

function FDIDocuments() {
    return (
        <Documents
            text="Please download our PDF's covering key policy information about Named Young Driver Insurance."
            documents={[
                { text: 'Broker insert', url: brokerInsert },
                {
                    text: 'Annual Insurance policy booklet',
                    url: `${process.env.REACT_APP_API_BASE_URI}/proxy/document.php?product=5&documentType=3&return=download`,
                },
            ]}
        />
    );
}

export default FDIDocuments;
