import React from 'react';
import { VEHICLE_CAVEATS } from '../../../constants';
import ProductPage from '../../../marketing/components/product-page';
import background from '../../../marketing/media/ldi-hero.jpg';
import ldiProductImage from '../../../marketing/media/ldi-product-image.jpg';
import { Player } from '../../../modules/youtube';
import { testimonials } from '../constants';

function LDI() {
    return (
        <ProductPage
            title="Flexible learner insurance for practice in a parent’s or friend’s car"
            subtitle="Affordable alternative to being added as a named driver on a parent’s car."
            image={background}
            sections={[
                {
                    type: 'lead',
                    features: [
                        {
                            title: 'Earn up to £50 per policy',
                            body: 'You can earn up to £50 per new insurance policy sold and up to £40 per renewal.',
                            icon: 'pound-sign',
                        },
                        {
                            title: 'Instant quote',
                            body: 'Offer a quick quote in seconds and instant cover..',
                            icon: 'clock',
                        },
                        {
                            title: 'Friendly broker team',
                            body: 'Our dedicated support team is on hand to help with any questions.',
                            icon: 'customer-service',
                        },
                    ],
                },
                {
                    type: 'register-cta',
                    title: 'What is Learner Driver Insurance?',
                    subtitle:
                        'Learner insurance is short term cover that sits alongside the main policy, providing fully comprehensive cover when the young driver is behind the wheel.',
                    features: [
                        'Exclusively covers learners aged 17-34 when using a friend or family member’s car',
                        'No risk to owners No Claims Discount',
                        'Flexible insurance from 1 month to 8 months, with 7 & 14 day options at renewal',
                        VEHICLE_CAVEATS,
                    ],
                    media: (
                        <img
                            src={ldiProductImage}
                            alt="Young lady learner driver leaning out of car window with thumbs up"
                            width="490px"
                            height="290px"
                        />
                    ),
                },
                { type: 'young-driver-reviews' },
                {
                    type: 'register-cta',
                    title: 'Why offer Marmalade Learner Driver Insurance?',
                    subtitle: 'The policy helps learners to get those all-important extra hours of driving practice.',
                    reverse: true,
                    features: [
                        'Grow your young driver business',
                        'Young driver specialists',
                        'Easy to use portal to offer quotes',
                        'Free marketing material',
                    ],
                    media: <Player videoId="z0qQLX3T6UM" />,
                },
                {
                    type: 'customer-testimonials',
                    testimonials,
                },
            ]}
        />
    );
}

export default LDI;
