import glamorous, { Ul } from 'glamorous';
import { bool, func } from 'prop-types';
import React from 'react';
import checkPrimary from '../../marketing/media/check-primary.svg';
import checkSuccess from '../../marketing/media/check-success.svg';
import check from '../../marketing/media/check.svg';

const liStyles = ({ primary, success }) => ({ theme, color, children, ...rest }) => ({
    position: 'relative',
    marginLeft: '1.5em',
    '&:before': {
        position: 'absolute',
        content: ' ',
        color: color || theme.colorPrimary,
        display: 'inline-block',
        width: '1em',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: '0.5em',
        top: 0,
        bottom: 0,
        left: '-1.5em',
        backgroundImage: `url(${primary ? checkPrimary : success ? checkSuccess : check})`,
        ...rest,
    },
});

const Li = options => glamorous('li')(liStyles(options));

BulletList.propTypes = {
    primary: bool,
    success: bool,
    children: func.isRequired,
};

function BulletList({ primary = false, success = false, children, ...rest }) {
    return (
        <Ul {...rest} listStyle="none" textAlign="left" paddingLeft={0}>
            {children(Li({ primary, success }))}
        </Ul>
    );
}

export default BulletList;
